import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
 
// 用来存储数据
const state = {
    imgUrl:localStorage.getItem('imgUrl') || 'https://www.libai.live/en.png',
    // 首页数据
    indexList:{},
    // 用户数据
    userInfo: JSON.parse(localStorage.getItem('userinfo')) || {}
}
// 操作数据
const mutations = {
   setImg(state,imgUrl) {
    localStorage.setItem('imgUrl',imgUrl)
    window.location.reload()
   },
   setListData(state,val) {
     state.indexList = val
    // localStorage.setItem('indexList',JSON.stringify(val))
   },
   setUserinfo(state,val) {
    // state.userInfo = val.userinfo
    localStorage.setItem('userinfo',JSON.stringify(val.userinfo))
    // console.log(localStorage.getItem('userinfo'));
   }
}

// 响应组件中的事件
const actions = {

}
// 用来将state数据进行加工
const getters = {
 
}
// 新建并暴露store
export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters
})
